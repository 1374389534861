<template>
    <div class="p-0">
      <div class="bg-white p-8 rounded-lg shadow-xl py-8 mt-12">
        <h3 class="text-lg font-semibold">Documentação - Inteligência Artificial</h3>
        <div class="pb-12 mt-4">
  
          <!-- Layout Responsivo -->
          <div class="flex min-h-screen flex-row py-8">
            <!-- Sidebar -->
            <Menu />
            <!-- Main Content -->
            <main class="flex-1 px-6 bg-gray-100">
                <h1 class="text-2xl font-bold mb-4">1. Introdução ao Duotalk e ChatGPT</h1>
  <p class="mb-4">
    O Duotalk é um sistema de atendimento automatizado que utiliza a inteligência artificial do ChatGPT para fornecer respostas rápidas e personalizadas com base em uma base de conhecimento definida. Essa documentação visa esclarecer como o sistema funciona, suas limitações e como os clientes podem maximizar sua eficiência por meio de um prompt de atendimento adequado.
  </p>
  
  <h2 class="text-xl font-semibold mb-3">IA Conversacional</h2>
  <p class="mb-4">
    A IA Conversacional do Duotalk utiliza a tecnologia do ChatGPT, baseada no modelo de linguagem da OpenAI. Esse sistema é projetado para interagir de forma natural com os usuários, entendendo e respondendo em linguagem humana. Ao contrário de sistemas tradicionais de respostas automáticas, a IA Conversacional pode lidar com uma ampla variedade de perguntas e ajustar suas respostas com base no prompt configurado.
  </p>
  
  <h3 class="text-lg font-semibold mb-2">Principais pontos sobre a IA Conversacional:</h3>
  
  <ul class="list-disc list-inside mb-4">
    <li class="mb-2">
      <strong>Capacidade de entender e gerar linguagem natural:</strong> A IA é treinada para reconhecer padrões de fala e construir respostas coerentes, mas precisa de um direcionamento claro para fornecer respostas precisas e alinhadas ao objetivo de cada empresa.
    </li>
    <li class="mb-2">
      <strong>Aprendizagem contínua:</strong> Embora o ChatGPT não aprenda em tempo real com as interações, o sistema pode ser ajustado com base no feedback dos usuários e nas interações anteriores, melhorando suas respostas ao longo do tempo através de novas orientações via prompt.
    </li>
    <li class="mb-2">
      <strong>Base de conhecimento estruturada:</strong> Ela responde com base nas informações fornecidas pela base de conhecimento da empresa, o que significa que quanto mais organizada e clara for essa base, melhores serão as respostas.
    </li>
    <li class="mb-2">
      <strong>Limitações e contexto:</strong> A IA é muito boa em responder a perguntas diretas e em fornecer informações específicas. No entanto, pode apresentar limitações com contextos complexos ou informações que não estejam bem descritas em sua base de conhecimento, gerando algumas alucinações.
    </li>
  </ul>
  
  <p class="mb-4">
    Para mais detalhes sobre como funciona a API da OpenAI, consulte a 
    <a href="https://openai.com/docs" class="text-blue-500 underline hover:text-blue-700" target="_blank">documentação oficial da OpenAI</a>.
  </p>
            </main>
          </div>
  
        </div>
      </div>
    </div>
  </template>
    
    
      
      <script>
      import Menu from './MenuFaqIA.vue'; // Importando o menu
      export default {
        name: 'ChatbotIA-Duotalk',
        components: {
            Menu
        }
        
     
    };
    
    </script>
    
    
    <style scoped>

    
    .faq-item h3 {
      cursor: pointer;
      padding: 10px;
      background-color: #f2f2f2;
      border-radius: 5px;
      margin: 5px 0;
      transition: background-color 0.3s ease;
    }
    
    .faq-item h3:hover {
      background-color: #e0e0e0;
    }
    
    .faq-item h3.active {
      background-color: #d0d0d0;
    }
    
    .faq-item p {
      margin: 10px 0;
      padding: 10px;
      background-color: #fafafa;
      border-left: 5px solid #cccccc;
    }
    </style>
   